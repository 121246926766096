import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";
// import ContactForm from "../ContactForm/ContactForm";

class Pricing extends Component {
  state = {
    pricings: [
      {
        id: 3,
        title: "AZURE Cloud Fountainhead",
        price: "29.90",
        duration: "MONTH",
        isRibbon: true,
        topDescription:
          "Profitieren Sie von einem Premium-Hosting-Angebot, das speziell auf Ihre Anforderungen zugeschnitten ist.",
        bottomDescription:
          "Setzen Sie auf Qualität und Sicherheit mit unserem AZURE Cloud Fountainhead Hosting-Paket.",
        features: {
          discount: "30% Rabatt",
          support:
            "Immer an Ihrer Seite mit inklusivem Support von bis zu 5 Stunden pro Monat.",
          domain: "Ihr digitaler Ankerpunkt, ganz ohne zusätzliche Kosten.",
          pricing: "Auf uns! Komplett inklusive.",
          dataImporter: "Problemloser Datenimport, ebenfalls inklusive.",
          serverStandard:
            "Westeuropa - für schnelle und zuverlässige Verbindungen.",
          access:
            "Loggen Sie sich einfach mit Ihrem vorhandenen Microsoft Konto ein.",
          security:
            "Zweifaktorauthentifizierung (2FA) standardmäßig inklusive.",
        },
      },

      {
        id: 2,
        title: "Kunden Cloud",
        price: "19.90",
        duration: "MONTH",
        bottomDescription:
          "Wählen Sie die 'Kunden Cloud', wenn Sie einen individuellen und flexiblen Hosting-Service wünschen, der sich genau an Ihre Bedürfnisse anpasst.",
        features: {
          pricingModel: "Standardpreis",
          support: "Individualisiert durch einen separaten SLA-Vertrag.",
          pricing: "Wird separat berechnet.",
          dataImporter: "Ohne zusätzliche Kosten – inklusive.",
          domain: "Separat verfügbar.",
          serverStandard:
            "In Westeuropa, genaue Ausrichtung je nach Vereinbarung.",
          access:
            "Login über Ihr vorhandenes Microsoft Konto – je nach individueller Vereinbarung.",
          security:
            "Zweifaktorauthentifizierung (2FA) – Umsetzung je nach Vereinbarung.",
        },
      },
      {
        id: 1,
        title: "On premise",
        price: "9.90",
        duration: "MONTH",
        features: {
          support:
            "Individualisierte Unterstützung durch einen separaten SLA-Vertrag.",
          pricing: "Wird separat berechnet.",
          dataImporter: "Verfügbar als separate Option.",
          domain: "Separat erhältlich.",
          serverStandard:
            "In Westeuropa, genaue Lokalisierung je nach individueller Vereinbarung.",
          access:
            "Login über Ihr vorhandenes Microsoft Konto – Implementierung nach Vereinbarung.",
          security:
            "Zweifaktorauthentifizierung (2FA) – Anpassung je nach individuellem Bedarf.",
        },
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-light"
          id="pricing">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Hosting"
              description="Entdecken Sie die Möglichkeiten mit uns. Fordern Sie eine Demo an, und gemeinsam finden wir den optimalen Hosting-Plan, der perfekt auf Ihre Geschäftsanforderungen zugeschnitten ist."
            />

            <Row className="mt-5">
              {this.state.pricings.map((pricing, key) => (
                <PricingBox
                  key={key}
                  pricing={pricing}
                />
              ))}
            </Row>

            {/* <Col className="pt-5" lg={{ size: 8, offset: 2 }}>
              <ContactForm />
            </Col> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
