import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          id="features">
          <Container>
            <Row className="align-items-center">
              <Col
                lg="5"
                className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>Erfahrung und Vertrauen, auf die Sie zählen können:</h3>
                  {/* <p className="text-muted web-desc">
                    Schluss mit der Excel-Buchhaltung: Die webbasierte Lösung
                    zur Erfassung und Bewertung von Leasingnehmer- und
                    Leasinggebersachverhalten nach IFRS 16
                  </p>
                  <p className="text-muted web-desc">
                    Erfahrung und Vertrauen, auf die Sie zählen können:
                  </p> */}
                  <ul className="text-muted list-unstyled mt-4 features-item-list">
                    <li className="">
                      <strong>5+ Jahre bewährte Zuverlässigkeit:</strong> Unsere
                      Softwarelösungen sind seit über fünf Jahren erfolgreich im
                      Einsatz.
                    </li>
                    <li className="">
                      <strong>100+ Installationen:</strong> Über hundert
                      erfolgreiche Implementierungen sprechen für unsere
                      Erfahrung und Zuverlässigkeit.
                    </li>
                    <li className="">
                      <strong>20+ standardisierte Reports:</strong> Profitieren
                      Sie von unserer umfangreichen Bibliothek an
                      vorkonfigurierten Reports.
                    </li>
                    <li className="">
                      <strong>10.000+ Stunden Programmierung:</strong> Unser
                      Engagement und unsere Investitionen in Entwicklung
                      spiegeln sich in über 10.000 Stunden qualitativ
                      hochwertiger Programmierung wider.
                    </li>
                    <li className="">
                      <strong>
                        Exklusive Partnerschaft mit einem Mitglied der
                        BIG4-Beratungshäuser:
                      </strong>{" "}
                      Unsere enge Zusammenarbeit mit einem führenden
                      Beratungshaus gewährleistet, dass unsere Lösungen den
                      höchsten Standards entsprechen.
                    </li>
                  </ul>
                  <p className="text-muted web-desc">
                    Setzen Sie auf eine Software, die Komplexität reduziert und
                    Transparenz in Ihre Finanzberichterstattung bringt.
                  </p>
                  <a
                    href="/#contact"
                    className="btn btn-primary mt-4 waves-effect waves-light">
                    Try it Free <i className="mdi mdi-arrow-right"></i>
                  </a>
                </div>
              </Col>
              <Col
                lg={{ size: 7, order: 2 }}
                xs={{ order: 1 }}>
                <div className="features-img mx-auto me-lg-0">
                  <img
                    src="assets/images/growth-analytics.svg"
                    alt="macbook"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
