import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "WEBBASIERT",
        icon: "pe-7s-server",
        description:
          "Greifen Sie von überall und zu jeder Zeit auf Ihre Daten zu – alles, was Sie brauchen, ist eine Internetverbindung.",
      },
      {
        title: "INTERNATIONAL",
        icon: "pe-7s-global",
        description:
          "Weltweit einsatzbereit: Wählen Sie flexibel zwischen der deutschen und englischen Sprachversion für einen konzernweiten Einsatz.",
      },
      {
        title: "INDIVIDUELL",
        icon: "pe-7s-note2",
        description:
          "Maßgeschneidert für Ihr Unternehmen: Generieren Sie spezifische Reports und verwalten Sie den Upload und Download von Daten nach Ihren Bedürfnissen.",
      },
      {
        title: "TRANSPARENT",
        icon: "pe-7s-box1",
        description:
          "Behalten Sie den Überblick: Zentrale Archivierung sämtlicher Sachverhalte mit vollständiger Dokumentation ihrer historischen Entwicklung.",
      },
      {
        title: "FLEXIBEL",
        icon: "pe-7s-cloud",
        description:
          "Optimale Anpassungsfähigkeit: Profitieren Sie von einer cloudbasierten Lösung, die auch direkt durch den Softwareanbieter gehostet werden kann.",
      },
      {
        title: "REVISIONSSICHER",
        icon: "pe-7s-note",
        description:
          "Minimieren Sie Risiken: Durch die Reduktion manueller Tätigkeiten werden Eingabe- und Verarbeitungsfehler erheblich reduziert.",
      },
      {
        title: "STANDARDISIERT",
        icon: "pe-7s-check",
        description:
          "Setzen Sie auf Compliance: Gewährleisten Sie konforme Berechnungen für Rückbau- und Rekultivierungssachverhalte gemäß geltenden Standards.",
      },
      {
        title: "KOSTENSPAREND",
        icon: "pe-7s-piggy",
        description:
          "Mehr Effizienz, weniger Kosten: Durch konsequente Standardisierung und Automatisierung optimieren Sie Prozesse und senken Betriebskosten.",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-light"
          id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Fokus auf Finanzsoftware"
              description={`Entdecken Sie innovative und leistungsstarke Lösungen für die Erfassung und Bewertung von Leasingverhältnissen gemäß IFRS 16 sowie Rückbau- und Rekultivierungsrückstellungen nach HGB, deutschem Steuerrecht und IFRS.\n 
              Wir kombinieren technologische Kompetenz mit finanzfachlichem Know-how.
              `}
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;

// class Services extends Component {
//   state = {
//     services: [
//       {
//         title: "WEBBASIERT",
//         icon: "pe-7s-server",
//         description: "Zugriff jederzeit und an jedem Ort verfügbar ",
//       },
//       {
//         title: "INTERNATIONAL",
//         icon: "pe-7s-global",
//         description:
//           "Konzernweiter Einsatz durch flexible Einstellung der deutschen, englischer oder portugiesischer Sprachversion",
//       },
//       {
//         title: "INDIVIDUELL",
//         icon: "pe-7s-note2",
//         description:
//           "Unterspezifische Generierung von Reports zum Upload und Download von Daten",
//       },
//       {
//         title: "TRANSPARENT",
//         icon: "pe-7s-box1",
//         description:
//           "Zentrale Archivierung aller Sachverhalte inklusive deren historischer Entwicklung",
//       },
//       {
//         title: "FLEXIBEL",
//         icon: "pe-7s-cloud",
//         description:
//           "Cloudbasierte Bereitstellung durch Hosting direkt beim Softwareanbieter",
//       },
//       {
//         title: "REVISIONSSICHER",
//         icon: "pe-7s-note",
//         description:
//           "Reduktion von manuellen Tätigkeiten und einhergehender Eingabe- und Verarbeitungsfehler",
//       },
//       {
//         title: "STANDARDISIERT",
//         icon: "pe-7s-check",
//         description:
//           "Compliance-konforme Berechnung von Leasingnehmer- und Leasinggebersachverhalten nach IFRS 16",
//       },
//       {
//         title: "KOSTENSPAREND",
//         icon: "pe-7s-piggy",
//         description:
//           "Effizienzsteigerung durch Standardisierung und Automatisierung",
//       },
//     ],
//   };
//   render() {
//     return (
//       <React.Fragment>
//         <section
//           className="section bg-light"
//           id="services">
//           <Container>
//             {/* Render section title */}
//             <SectionTitle
//               title="Fokus auf Finanzsoftware"
//               description={`Entdecken Sie innovative und leistungsstarke Lösungen für die Erfassung und Bewertung von Leasingverhältnissen gemäß IFRS 16 sowie Rückbau- und Rekultivierungsrückstellungen nach HGB, deutschem Steuerrecht und IFRS.\n
//               Wir kombinieren technologische Kompetenz mit finanzfachlichem Know-how.
//               `}
//             />

//             <Row className="mt-5">
//               {/* render service box */}
//               {this.state.services.map((service, key) => (
//                 <ServiceBox
//                   key={key}
//                   title={service.title}
//                   icon={service.icon}
//                   description={service.description}
//                 />
//               ))}
//             </Row>
//           </Container>
//         </section>
//       </React.Fragment>
//     );
//   }
// }
