import Footer from "../../components/Footer/footer";
import { Navbar, NavbarBrand, Container } from "reactstrap";
import { useEffect } from "react";

//Import Stickey Header
import StickyHeader from "react-sticky-header";

const CookiePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StickyHeader
        stickyOffset={-0}
        header={
          <Navbar
            expand="lg"
            fixed="top"
            className="navbar-custom sticky sticky-dark">
            <Container>
              <NavbarBrand
                className="logo"
                href="/">
                <img
                  src="assets/images/logo.png"
                  alt="..."
                />
              </NavbarBrand>
            </Container>
          </Navbar>
        }></StickyHeader>
      <section className="section">
        <Container>
          <div className="pt-5">
            <h2 className="article__title">Datenschutzerklärung</h2>
            <div className="article__chapter chapter">
              <p className="chapter__text">
                Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
                Datenschutz hat einen besonders hohen Stellenwert für die
                Geschäftsleitung der FOUNTAINHEAD IT GmbH. Eine Nutzung der
                Internetseiten der FOUNTAINHEAD IT GmbH ist grundsätzlich ohne
                jede Angabe personenbezogener Daten möglich. Sofern eine
                betroffene Person besondere Services unseres Unternehmens über
                unsere Internetseite in Anspruch nehmen möchte, könnte jedoch
                eine Verarbeitung personenbezogener Daten erforderlich werden.
                Ist die Verarbeitung personenbezogener Daten erforderlich und
                besteht für eine solche Verarbeitung keine gesetzliche
                Grundlage, holen wir generell eine Einwilligung der betroffenen
                Person ein.
                <br />
                Die Verarbeitung personenbezogener Daten, beispielsweise des
                Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer
                betroffenen Person, erfolgt stets im Einklang mit der
                Datenschutz-Grundverordnung und in Übereinstimmung mit den für
                die FOUNTAINHEAD IT GmbH geltenden landesspezifischen
                Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
                möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
                Zweck der von uns erhobenen, genutzten und verarbeiteten
                personenbezogenen Daten informieren. Ferner werden betroffene
                Personen mittels dieser Datenschutzerklärung über die ihnen
                zustehenden Rechte aufgeklärt.
                <br />
                Die FOUNTAINHEAD IT GmbH hat als für die Verarbeitung
                Verantwortlicher zahlreiche technische und organisatorische
                Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der
                über diese Internetseite verarbeiteten personenbezogenen Daten
                sicherzustellen. Dennoch können Internetbasierte
                Datenübertragungen grundsätzlich Sicherheitslücken aufweisen,
                sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus
                diesem Grund steht es jeder betroffenen Person frei,
                personenbezogene Daten auch auf alternativen Wegen,
                beispielsweise telefonisch, an uns zu übermitteln.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">1. Begriffsbestimmungen</h4>
              <p className="chapter__text">
                Die Datenschutzerklärung der FOUNTAINHEAD IT GmbH beruht auf den
                Begrifflichkeiten, die durch den Europäischen Richtlinien- und
                Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll
                sowohl für die Öffentlichkeit als auch für unsere Kunden und
                Geschäftspartner einfach lesbar und verständlich sein. Um dies
                zu gewährleisten, möchten wir vorab die verwendeten
                Begrifflichkeiten erläutern.
                <br />
                Wir verwenden in dieser Datenschutzerklärung unter anderem die
                folgenden Begriffe:
              </p>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  a) personenbezogene Daten
                </h5>
                <p className="part-chapter__text">
                  Personenbezogene Daten sind alle Informationen, die sich auf
                  eine identifizierte oder identifizierbare natürliche Person
                  (im Folgenden „betroffene Person“) beziehen. Als
                  identifizierbar wird eine natürliche Person angesehen, die
                  direkt oder indirekt, insbesondere mittels Zuordnung zu einer
                  Kennung wie einem Namen, zu einer Kennnummer, zu
                  Standortdaten, zu einer Online-Kennung oder zu einem oder
                  mehreren besonderen Merkmalen, die Ausdruck der physischen,
                  physiologischen, genetischen, psychischen, wirtschaftlichen,
                  kulturellen oder sozialen Identität dieser natürlichen Person
                  sind, identifiziert werden kann.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">b) betroffene Person</h5>
                <p className="part-chapter__text">
                  Betroffene Person ist jede identifizierte oder
                  identifizierbare natürliche Person, deren personenbezogene
                  Daten von dem für die Verarbeitung Verantwortlichen
                  verarbeitet werden.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">c) Verarbeitung</h5>
                <p className="part-chapter__text">
                  Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
                  Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
                  im Zusammenhang mit personenbezogenen Daten wie das Erheben,
                  das Erfassen, die Organisation, das Ordnen, die Speicherung,
                  die Anpassung oder Veränderung, das Auslesen, das Abfragen,
                  die Verwendung, die Offenlegung durch Übermittlung,
                  Verbreitung oder eine andere Form der Bereitstellung, den
                  Abgleich oder die Verknüpfung, die Einschränkung, das Löschen
                  oder die Vernichtung.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  d) Einschränkung der Verarbeitung
                </h5>
                <p className="part-chapter__text">
                  Einschränkung der Verarbeitung ist die Markierung
                  gespeicherter personenbezogener Daten mit dem Ziel, ihre
                  künftige Verarbeitung einzuschränken.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">e) Profiling</h5>
                <p className="part-chapter__text">
                  Profiling ist jede Art der automatisierten Verarbeitung
                  personenbezogener Daten, die darin besteht, dass diese
                  personenbezogenen Daten verwendet werden, um bestimmte
                  persönliche Aspekte, die sich auf eine natürliche Person
                  beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
                  Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
                  persönlicher Vorlieben, Interessen, Zuverlässigkeit,
                  Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
                  Person zu analysieren oder vorherzusagen.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">f) Pseudonymisierung</h5>
                <p className="part-chapter__text">
                  Pseudonymisierung ist die Verarbeitung personenbezogener Daten
                  in einer Weise, auf welche die personenbezogenen Daten ohne
                  Hinzuziehung zusätzlicher Informationen nicht mehr einer
                  spezifischen betroffenen Person zugeordnet werden können,
                  sofern diese zusätzlichen Informationen gesondert aufbewahrt
                  werden und technischen und organisatorischen Maßnahmen
                  unterliegen, die gewährleisten, dass die personenbezogenen
                  Daten nicht einer identifizierten oder identifizierbaren
                  natürlichen Person zugewiesen werden.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  g) Verantwortlicher oder für die Verarbeitung Verantwortlicher
                </h5>
                <p className="part-chapter__text">
                  Verantwortlicher oder für die Verarbeitung Verantwortlicher
                  ist die natürliche oder juristische Person, Behörde,
                  Einrichtung oder andere Stelle, die allein oder gemeinsam mit
                  anderen über die Zwecke und Mittel der Verarbeitung von
                  personenbezogenen Daten entscheidet. Sind die Zwecke und
                  Mittel dieser Verarbeitung durch das Unionsrecht oder das
                  Recht der Mitgliedstaaten vorgegeben, so kann der
                  Verantwortliche beziehungsweise können die bestimmten
                  Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht
                  der Mitgliedstaaten vorgesehen werden.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">h) Auftragsverarbeiter</h5>
                <p className="part-chapter__text">
                  Auftragsverarbeiter ist eine natürliche oder juristische
                  Person, Behörde, Einrichtung oder andere Stelle, die
                  personenbezogene Daten im Auftrag des Verantwortlichen
                  verarbeitet.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">i) Empfänger</h5>
                <p className="part-chapter__text">
                  Empfänger ist eine natürliche oder juristische Person,
                  Behörde, Einrichtung oder andere Stelle, der personenbezogene
                  Daten offengelegt werden, unabhängig davon, ob es sich bei ihr
                  um einen Dritten handelt oder nicht. Behörden, die im Rahmen
                  eines bestimmten Untersuchungsauftrags nach dem Unionsrecht
                  oder dem Recht der Mitgliedstaaten möglicherweise
                  personenbezogene Daten erhalten, gelten jedoch nicht als
                  Empfänger.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">j) Dritter</h5>
                <p className="part-chapter__text">
                  Dritter ist eine natürliche oder juristische Person, Behörde,
                  Einrichtung oder andere Stelle außer der betroffenen Person,
                  dem Verantwortlichen, dem Auftragsverarbeiter und den
                  Personen, die unter der unmittelbaren Verantwortung des
                  Verantwortlichen oder des Auftragsverarbeiters befugt sind,
                  die personenbezogenen Daten zu verarbeiten.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">k) Einwilligung</h5>
                <p className="part-chapter__text">
                  Einwilligung ist jede von der betroffenen Person freiwillig
                  für den bestimmten Fall in informierter Weise und
                  unmissverständlich abgegebene Willensbekundung in Form einer
                  Erklärung oder einer sonstigen eindeutigen bestätigenden
                  Handlung, mit der die betroffene Person zu verstehen gibt,
                  dass sie mit der Verarbeitung der sie betreffenden
                  personenbezogenen Daten einverstanden ist.
                </p>
              </div>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                2. Name und Anschrift des für die Verarbeitung Verantwortlichen
              </h4>
              <p className="chapter__text">
                Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
                sonstiger in den Mitgliedstaaten der Europäischen Union
                geltenden
                <br />
                Datenschutzgesetze und anderer Bestimmungen mit
                datenschutzrechtlichem Charakter ist die: FOUNTAINHEAD IT GmbH
                <br />
                Römerstraße 245 <br />
                69126 Heidelberg <br />
                Deutschland <br />
                Tel.: +49 (0) 6221 / 67 3456-5 <br />
                E-Mail:
                <a href="office@praetoriusbelykh.com">
                  office@praetoriusbelykh.com
                </a>
                <br />
                Website:
                <a href="https://fountainhead-it.com/">
                  https://fountainhead-it.com/
                </a>
                <br />
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                3. Name und Anschrift des Datenschutzbeauftragten
              </h4>
              <p className="chapter__text">
                Der Datenschutzbeauftragte des für die Verarbeitung
                Verantwortlichen ist:
                <br />
                Holger Praetorius <br />
                Römerstraße 245 <br />
                69126 Heidelberg <br />
                Jede betroffene Person kann sich jederzeit bei allen Fragen und
                Anregungen zum Datenschutz direkt an unseren <br />
                Datenschutzbeauftragten wenden. <br />
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">4. Cookies</h4>
              <p className="chapter__text">
                Die Internetseiten der FOUNTAINHEAD IT GmbH verwenden Cookies.
                Cookies sind Textdateien, welche über einen Internetbrowser auf
                einem Computersystem abgelegt und gespeichert werden.
              </p>
              <p className="chapter__text">
                Zahlreiche Internetseiten und Server verwenden Cookies. Viele
                Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist
                eine eindeutige Kennung des Cookies. Sie besteht aus einer
                Zeichenfolge, durch welche Internetseiten und Server dem
                konkreten Internetbrowser zugeordnet werden können, in dem das
                Cookie gespeichert wurde. Dies ermöglicht es den besuchten
                Internetseiten und Servern, den individuellen Browser der
                betroffenen Person von anderen Internetbrowsern, die andere
                Cookies enthalten, zu unterscheiden. Ein bestimmter
                Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt
                und identifiziert werden.
              </p>
              <p className="chapter__text">
                Durch den Einsatz von Cookies kann die FOUNTAINHEAD IT GmbH den
                Nutzern dieser Internetseite nutzerfreundlichere Services
                bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
              </p>
              <p className="chapter__text">
                Mittels eines Cookies können die Informationen und Angebote auf
                unserer Internetseite im Sinne des Benutzers optimiert werden.
                Cookies ermöglichen uns, wie bereits erwähnt, die Benutzer
                unserer Internetseite wiederzuerkennen. Zweck dieser
                Wiedererkennung ist es, den Nutzern die Verwendung unserer
                Internetseite zu erleichtern. Der Benutzer einer Internetseite,
                die Cookies verwendet, muss beispielsweise nicht bei jedem
                Besuch der Internetseite erneut seine Zugangsdaten eingeben,
                weil dies von der Internetseite und dem auf dem Computersystem
                des Benutzers abgelegten Cookie übernommen wird. Ein weiteres
                Beispiel ist das Cookie eines Warenkorbes im Online-Shop. Der
                Online-Shop merkt sich die Artikel, die ein Kunde in den
                virtuellen Warenkorb gelegt hat, über ein Cookie.
              </p>
              <p className="chapter__text">
                Die betroffene Person kann die Setzung von Cookies durch unsere
                Internetseite jederzeit mittels einer entsprechenden Einstellung
                des genutzten Internetbrowsers verhindern und damit der Setzung
                von Cookies dauerhaft widersprechen. Ferner können bereits
                gesetzte Cookies jederzeit über einen Internetbrowser oder
                andere Softwareprogramme gelöscht werden. Dies ist in allen
                gängigen Internetbrowsern möglich. Deaktiviert die betroffene
                Person die Setzung von Cookies in dem genutzten Internetbrowser,
                sind unter Umständen nicht alle Funktionen unserer Internetseite
                vollumfänglich nutzbar.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                5. Erfassung von allgemeinen Daten und Informationen
              </h4>
              <p className="chapter__text">
                Die Internetseite der FOUNTAINHEAD IT GmbH erfasst mit jedem
                Aufruf der Internetseite durch eine betroffene Person oder ein
                automatisiertes System eine Reihe von allgemeinen Daten und
                Informationen. Diese allgemeinen Daten und Informationen werden
                in den Logfiles des Servers gespeichert. Erfasst werden können
                die (1) verwendeten Browsertypen und Versionen, (2) das vom
                zugreifenden System verwendete Betriebssystem, (3) die
                Internetseite, von welcher ein zugreifendes System auf unsere
                Internetseite gelangt (sogenannte Referrer), (4) die
                Unterwebseiten, welche über ein zugreifendes System auf unserer
                Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
                eines Zugriffs auf die Internetseite, (6) eine
                Internet-Protokoll-Adresse (IP-Adresse), (7) der
                Internet-Service-Provider des zugreifenden Systems und (8)
                sonstige ähnliche Daten und Informationen, die der
                Gefahrenabwehr im Falle von Angriffen auf unsere
                informationstechnologischen Systeme dienen.
              </p>
              <p className="chapter__text">
                Bei der Nutzung dieser allgemeinen Daten und Informationen zieht
                die FOUNTAINHEAD IT GmbH keine Rückschlüsse auf die betroffene
                Person. Diese Informationen werden vielmehr benötigt, um (1) die
                Inhalte unserer Internetseite korrekt auszuliefern, (2) die
                Inhalte unserer Internetseite sowie die Werbung für diese zu
                optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
                informationstechnologischen Systeme und der Technik unserer
                Internetseite zu gewährleisten sowie (4) um
                Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                Strafverfolgung notwendigen Informationen bereitzustellen. Diese
                anonym erhobenen Daten und Informationen werden durch die
                FOUNTAINHEAD IT GmbH daher einerseits statistisch und ferner mit
                dem Ziel ausgewertet, den Datenschutz und die Datensicherheit in
                unserem Unternehmen zu erhöhen, um letztlich ein optimales
                Schutzniveau für die von uns verarbeiteten personenbezogenen
                Daten sicherzustellen. Die anonymen Daten der Server-Logfiles
                werden getrennt von allen durch eine betroffene Person
                angegebenen personenbezogenen Daten gespeichert.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                6. Registrierung auf unserer Internetseite
              </h4>
              <p className="chapter__text">
                Die betroffene Person hat die Möglichkeit, sich auf der
                Internetseite des für die Verarbeitung Verantwortlichen unter
                Angabe von personenbezogenen Daten zu registrieren. Welche
                personenbezogenen Daten dabei an den für die Verarbeitung
                Verantwortlichen übermittelt werden, ergibt sich aus der
                jeweiligen Eingabemaske, die für die Registrierung verwendet
                wird. Die von der betroffenen Person eingegebenen
                personenbezogenen Daten werden ausschließlich für die interne
                Verwendung bei dem für die Verarbeitung Verantwortlichen und für
                eigene Zwecke erhoben und gespeichert. Der für die Verarbeitung
                Verantwortliche kann die Weitergabe an einen oder mehrere
                Auftragsverarbeiter, beispielsweise einen Paketdienstleister,
                veranlassen, der die personenbezogenen Daten ebenfalls
                ausschließlich für eine interne Verwendung, die dem für die
                Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.
              </p>
              <p className="chapter__text">
                Durch eine Registrierung auf der Internetseite des für die
                Verarbeitung Verantwortlichen wird ferner die vom
                Internet-Service-Provider (ISP) der betroffenen Person vergebene
                IP-Adresse, das Datum sowie die Uhrzeit der Registrierung
                gespeichert. Die Speicherung dieser Daten erfolgt vor dem
                Hintergrund, dass nur so der Missbrauch unserer Dienste
                verhindert werden kann, und diese Daten im Bedarfsfall
                ermöglichen, begangene Straftaten aufzuklären. Insofern ist die
                Speicherung dieser Daten zur Absicherung des für die
                Verarbeitung Verantwortlichen erforderlich. Eine Weitergabe
                dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern keine
                gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe
                der Strafverfolgung dient.
              </p>
              <p className="chapter__text">
                Die Registrierung der betroffenen Person unter freiwilliger
                Angabe personenbezogener Daten dient dem für die Verarbeitung
                Verantwortlichen dazu, der betroffenen Person Inhalte oder
                Leistungen anzubieten, die aufgrund der Natur der Sache nur
                registrierten Benutzern angeboten werden können. Registrierten
                Personen steht die Möglichkeit frei, die bei der Registrierung
                angegebenen personenbezogenen Daten jederzeit abzuändern oder
                vollständig aus dem Datenbestand des für die Verarbeitung
                Verantwortlichen löschen zu lassen.
              </p>
              <p className="chapter__text">
                Der für die Verarbeitung Verantwortliche erteilt jeder
                betroffenen Person jederzeit auf Anfrage Auskunft darüber,
                welche personenbezogenen Daten über die betroffene Person
                gespeichert sind. Ferner berichtigt oder löscht der für die
                Verarbeitung Verantwortliche personenbezogene Daten auf Wunsch
                oder Hinweis der betroffenen Person, soweit dem keine
                gesetzlichen Aufbewahrungspflichten entgegenstehen. Die
                Gesamtheit der Mitarbeiter des für die Verarbeitung
                Verantwortlichen stehen der betroffenen Person in diesem
                Zusammenhang als Ansprechpartner zur Verfügung.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                7. Kontaktmöglichkeit über die Internetseite
              </h4>
              <p className="chapter__text">
                Die Internetseite der FOUNTAINHEAD IT GmbH enthält aufgrund von
                gesetzlichen Vorschriften Angaben, die eine schnelle
                elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine
                unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls
                eine allgemeine Adresse der sogenannten elektronischen Post
                (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per
                E-Mail oder über ein Kontaktformular den Kontakt mit dem für die
                Verarbeitung Verantwortlichen aufnimmt, werden die von der
                betroffenen Person übermittelten personenbezogenen Daten
                automatisch gespeichert. Solche auf freiwilliger Basis von einer
                betroffenen Person an den für die Verarbeitung Verantwortlichen
                übermittelten personenbezogenen Daten werden für Zwecke der
                Bearbeitung oder der Kontaktaufnahme zur betroffenen Person
                gespeichert. Es erfolgt keine Weitergabe dieser
                personenbezogenen Daten an Dritte.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                8. Routinemäßige Löschung und Sperrung von personenbezogenen
                Daten
              </h4>
              <p className="chapter__text">
                Der für die Verarbeitung Verantwortliche verarbeitet und
                speichert personenbezogene Daten der betroffenen Person nur für
                den Zeitraum, der zur Erreichung des Speicherungszwecks
                erforderlich ist oder sofern dies durch den Europäischen
                Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber
                in Gesetzen oder Vorschriften, welchen der für die Verarbeitung
                Verantwortliche unterliegt, vorgesehen wurde.
              </p>
              <p className="chapter__text">
                Entfällt der Speicherungszweck oder läuft eine vom Europäischen
                Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
                Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
                personenbezogenen Daten routinemäßig und entsprechend den
                gesetzlichen Vorschriften gesperrt oder gelöscht.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                9. Rechte der betroffenen Person
              </h4>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  a) Recht auf Bestätigung
                </h5>
                <p className="part-chapter__text">
                  Jede betroffene Person hat das vom Europäischen Richtlinien-
                  und Verordnungsgeber eingeräumte Recht, von dem für die
                  Verarbeitung Verantwortlichen eine Bestätigung darüber zu
                  verlangen, ob sie betreffende personenbezogene Daten
                  verarbeitet werden. Möchte eine betroffene Person dieses
                  Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu
                  jederzeit an einen Mitarbeiter des für die Verarbeitung
                  Verantwortlichen wenden.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">b) Recht auf Auskunft</h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, jederzeit von dem für die
                  Verarbeitung Verantwortlichen unentgeltliche Auskunft über die
                  zu seiner Person gespeicherten personenbezogenen Daten und
                  eine Kopie dieser Auskunft zu erhalten. Ferner hat der
                  Europäische Richtlinien- und Verordnungsgeber der betroffenen
                  Person Auskunft über folgende Informationen zugestanden:{" "}
                  <br />
                  die Verarbeitungszwecke <br />
                  die Kategorien personenbezogener Daten, die verarbeitet werden
                  die Empfänger oder Kategorien von Empfängern, gegenüber denen
                  die personenbezogenen Daten offengelegt worden sind oder noch
                  offengelegt werden, insbesondere bei Empfängern in
                  Drittländern oder bei internationalen Organisationen <br />
                  falls möglich die geplante Dauer, für die die
                  personenbezogenen Daten gespeichert werden, oder, falls dies
                  nicht möglich ist, die Kriterien für die Festlegung dieser
                  Dauer das Bestehen eines Rechts auf Berichtigung oder Löschung
                  der sie betreffenden personenbezogenen Daten oder auf
                  Einschränkung der Verarbeitung durch den Verantwortlichen oder
                  eines Widerspruchsrechts gegen diese Verarbeitung <br />
                  das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
                  wenn die personenbezogenen Daten nicht bei der betroffenen
                  Person erhoben werden: Alle verfügbaren Informationen über die
                  Herkunft der Daten das Bestehen einer automatisierten
                  Entscheidungsfindung einschließlich Profiling gemäß Artikel 22
                  Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen —
                  aussagekräftige Informationen über die involvierte Logik sowie
                  die Tragweite und die angestrebten Auswirkungen einer
                  derartigen Verarbeitung für die betroffene Person <br />
                  Ferner steht der betroffenen Person ein Auskunftsrecht darüber
                  zu, ob personenbezogene Daten an ein Drittland oder an eine
                  internationale Organisation übermittelt wurden. Sofern dies
                  der Fall ist, so steht der betroffenen Person im Übrigen das
                  Recht zu, Auskunft über die geeigneten Garantien im
                  Zusammenhang mit der Übermittlung zu erhalten. <br />
                  Möchte eine betroffene Person dieses Auskunftsrecht in
                  Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  c) Recht auf Berichtigung
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, die unverzügliche
                  Berichtigung sie betreffender unrichtiger personenbezogener
                  Daten zu verlangen. Ferner steht der betroffenen Person das
                  Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung,
                  die Vervollständigung unvollständiger personenbezogener Daten
                  — auch mittels einer ergänzenden Erklärung — zu verlangen.
                  Möchte eine betroffene Person dieses Berichtigungsrecht in
                  Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  d) Recht auf Löschung (Recht auf Vergessen werden)
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu
                  verlangen, dass die sie betreffenden personenbezogenen Daten
                  unverzüglich gelöscht werden, sofern einer der folgenden
                  Gründe zutrifft und soweit die Verarbeitung nicht erforderlich
                  ist:
                  <br />
                  Die personenbezogenen Daten wurden für solche Zwecke erhoben
                  oder auf sonstige Weise verarbeitet, für welche sie nicht mehr
                  notwendig sind. Die betroffene Person widerruft ihre
                  Einwilligung, auf die sich die Verarbeitung gemäß Art. 6 Abs.
                  1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO
                  stützte, und es fehlt an einer anderweitigen Rechtsgrundlage
                  für die Verarbeitung. Die betroffene Person legt gemäß Art. 21
                  Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein, und es
                  liegen keine vorrangigen berechtigten Gründe für die
                  Verarbeitung vor, oder die betroffene Person legt gemäß Art.
                  21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein. Die
                  personenbezogenen Daten wurden unrechtmäßig verarbeitet. Die
                  Löschung der personenbezogenen Daten ist zur Erfüllung einer
                  rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht
                  der Mitgliedstaaten erforderlich, dem der Verantwortliche
                  unterliegt. Die personenbezogenen Daten wurden in Bezug auf
                  angebotene Dienste der Informationsgesellschaft gemäß Art. 8
                  Abs. 1 DS-GVO erhoben.
                </p>
              </div>
              <p className="chapter__text">
                Sofern einer der oben genannten Gründe zutrifft und eine
                betroffene Person die Löschung von personenbezogenen Daten, die
                bei der FOUNTAINHEAD IT GmbH gespeichert sind, veranlassen
                möchte, kann sie sich hierzu jederzeit an einen Mitarbeiter des
                für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter
                der FOUNTAINHEAD IT GmbH wird veranlassen, dass dem
                Löschverlangen unverzüglich nachgekommen wird. Wurden die
                personenbezogenen Daten von der FOUNTAINHEAD IT GmbH öffentlich
                gemacht und ist unser Unternehmen als Verantwortlicher gemäß
                Art. 17 Abs. 1 DS-GVO zur Löschung der personenbezogenen Daten
                verpflichtet, so trifft die FOUNTAINHEAD IT GmbH unter
                Berücksichtigung der verfügbaren Technologie und der
                Implementierungskosten angemessene Maßnahmen, auch technischer
                Art, um andere für die Datenverarbeitung Verantwortliche, welche
                die veröffentlichten personenbezogenen Daten verarbeiten,
                darüber in Kenntnis zu setzen, dass die betroffene Person von
                diesen anderen für die Datenverarbeitung Verantwortlichen die
                Löschung sämtlicher Links zu diesen personenbezogenen Daten oder
                von Kopien oder Replikationen dieser personenbezogenen Daten
                verlangt hat, soweit die Verarbeitung nicht erforderlich ist.
                Der Mitarbeiter der FOUNTAINHEAD IT GmbH wird im Einzelfall das
                Notwendige veranlassen.
              </p>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  e) Recht auf Einschränkung der Verarbeitung
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, von dem Verantwortlichen die
                  Einschränkung der Verarbeitung zu verlangen, wenn eine der
                  folgenden Voraussetzungen gegeben ist:
                  <br />
                  Die Richtigkeit der personenbezogenen Daten wird von der
                  betroffenen Person bestritten, und zwar für eine Dauer, die es
                  dem Verantwortlichen ermöglicht, die Richtigkeit der
                  personenbezogenen Daten zu überprüfen.
                  <br />
                  Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt
                  die Löschung der personenbezogenen Daten ab und verlangt
                  stattdessen die Einschränkung der Nutzung der
                  personenbezogenen Daten.
                  <br />
                  Der Verantwortliche benötigt die personenbezogenen Daten für
                  die Zwecke der Verarbeitung nicht länger, die betroffene
                  Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
                  Verteidigung von Rechtsansprüchen.
                  <br />
                  Die betroffene Person hat Widerspruch gegen die Verarbeitung
                  gem. Art. 21 Abs. 1 DS-GVO eingelegt und es steht noch nicht
                  fest, ob die berechtigten Gründe des Verantwortlichen
                  gegenüber denen der betroffenen Person überwiegen.
                  <br />
                </p>
              </div>
              <p className="chapter__text">
                Sofern eine der oben genannten Voraussetzungen gegeben ist und
                eine betroffene Person die Einschränkung von personenbezogenen
                Daten, die bei der FOUNTAINHEAD IT GmbH gespeichert sind,
                verlangen möchte, kann sie sich hierzu jederzeit an einen
                Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                Der Mitarbeiter der FOUNTAINHEAD IT GmbH wird die Einschränkung
                der Verarbeitung veranlassen.
              </p>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  f) Recht auf Datenübertragbarkeit
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, die sie betreffenden
                  personenbezogenen Daten, welche durch die betroffene Person
                  einem Verantwortlichen bereitgestellt wurden, in einem
                  strukturierten, gängigen und maschinenlesbaren Format zu
                  erhalten. Sie hat außerdem das Recht, diese Daten einem
                  anderen Verantwortlichen ohne Behinderung durch den
                  Verantwortlichen, dem die personenbezogenen Daten
                  bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung
                  auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO
                  oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag
                  gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die
                  Verarbeitung mithilfe automatisierter Verfahren erfolgt,
                  sofern die Verarbeitung nicht für die Wahrnehmung einer
                  Aufgabe erforderlich ist, die im öffentlichen Interesse liegt
                  oder in Ausübung öffentlicher Gewalt erfolgt, welche dem
                  Verantwortlichen übertragen wurde.
                  <br />
                  Ferner hat die betroffene Person bei der Ausübung ihres Rechts
                  auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das
                  Recht, zu erwirken, dass die personenbezogenen Daten direkt
                  von einem Verantwortlichen an einen anderen Verantwortlichen
                  übermittelt werden, soweit dies technisch machbar ist und
                  sofern hiervon nicht die Rechte und Freiheiten anderer
                  Personen beeinträchtigt werden. Zur Geltendmachung des Rechts
                  auf Datenübertragbarkeit kann sich die betroffene Person
                  jederzeit an einen Mitarbeiter der FOUNTAINHEAD IT GmbH
                  wenden.
                  <br />
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  g) Recht auf Widerspruch
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, aus Gründen, die sich aus
                  ihrer besonderen Situation ergeben, jederzeit gegen die
                  Verarbeitung sie betreffender personenbezogener Daten, die
                  aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt,
                  Widerspruch einzulegen. Dies gilt auch für ein auf diese
                  Bestimmungen gestütztes Profiling.
                  <br />
                  Die FOUNTAINHEAD IT GmbH verarbeitet die personenbezogenen
                  Daten im Falle des Widerspruchs nicht mehr, es sei denn, wir
                  können zwingende schutzwürdige Gründe für die Verarbeitung
                  nachweisen, die den Interessen, Rechten und Freiheiten der
                  betroffenen Person überwiegen, oder die Verarbeitung dient der
                  Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen.
                  <br />
                  Verarbeitet die FOUNTAINHEAD IT GmbH personenbezogene Daten,
                  um Direktwerbung zu betreiben, so hat die betroffene Person
                  das Recht, jederzeit Widerspruch gegen die Verarbeitung der
                  personenbezogenen Daten zum Zwecke derartiger Werbung
                  einzulegen. Dies gilt auch für das Profiling, soweit es mit
                  solcher Direktwerbung in Verbindung steht. Widerspricht die
                  betroffene Person gegenüber der FOUNTAINHEAD IT GmbH der
                  Verarbeitung für Zwecke der Direktwerbung, so wird die
                  FOUNTAINHEAD IT GmbH die personenbezogenen Daten nicht mehr
                  für diese Zwecke verarbeiten.
                  <br />
                  Zudem hat die betroffene Person das Recht, aus Gründen, die
                  sich aus ihrer besonderen Situation ergeben, gegen die sie
                  betreffende Verarbeitung personenbezogener Daten, die bei der
                  FOUNTAINHEAD IT GmbH zu wissenschaftlichen oder historischen
                  Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89
                  Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn,
                  eine solche Verarbeitung ist zur Erfüllung einer im
                  öffentlichen Interesse liegenden Aufgabe erforderlich.
                  <br />
                  Zur Ausübung des Rechts auf Widerspruch kann sich die
                  betroffene Person direkt jeden Mitarbeiter der FOUNTAINHEAD IT
                  GmbH oder einen anderen Mitarbeiter wenden. Der betroffenen
                  Person steht es ferner frei, im Zusammenhang mit der Nutzung
                  von Diensten der Informationsgesellschaft, ungeachtet der
                  Richtlinie 2002/58/EG, ihr Widerspruchsrecht mittels
                  automatisierter Verfahren auszuüben, bei denen technische
                  Spezifikationen verwendet werden.
                  <br />
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  h) Automatisierte Entscheidungen im Einzelfall einschließlich
                  Profiling
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, nicht einer ausschließlich
                  auf einer automatisierten Verarbeitung — einschließlich
                  Profiling — beruhenden Entscheidung unterworfen zu werden, die
                  ihr gegenüber rechtliche Wirkung entfaltet oder sie in
                  ähnlicher Weise erheblich beeinträchtigt, sofern die
                  Entscheidung (1) nicht für den Abschluss oder die Erfüllung
                  eines Vertrags zwischen der betroffenen Person und dem
                  Verantwortlichen erforderlich ist, oder (2) aufgrund von
                  Rechtsvorschriften der Union oder der Mitgliedstaaten, denen
                  der Verantwortliche unterliegt, zulässig ist und diese
                  Rechtsvorschriften angemessene Maßnahmen zur Wahrung der
                  Rechte und Freiheiten sowie der berechtigten Interessen der
                  betroffenen Person enthalten oder (3) mit ausdrücklicher
                  Einwilligung der betroffenen Person erfolgt.
                  <br />
                  Ist die Entscheidung (1) für den Abschluss oder die Erfüllung
                  eines Vertrags zwischen der betroffenen Person und dem
                  Verantwortlichen erforderlich oder (2) erfolgt sie mit
                  ausdrücklicher Einwilligung der betroffenen Person, trifft die
                  FOUNTAINHEAD IT GmbH angemessene Maßnahmen, um die Rechte und
                  Freiheiten sowie die berechtigten Interessen der betroffenen
                  Person zu wahren, wozu mindestens das Recht auf Erwirkung des
                  Eingreifens einer Person seitens des Verantwortlichen, auf
                  Darlegung des eigenen Standpunkts und auf Anfechtung der
                  Entscheidung gehört.
                  <br />
                  Möchte die betroffene Person Rechte mit Bezug auf
                  automatisierte Entscheidungen geltend machen, kann sie sich
                  hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
                  Verantwortlichen wenden.
                  <br />
                </p>
              </div>
              <div className="chapter__part part-chapter">
                <h5 className="part-chapter__title">
                  i) Recht auf Widerruf einer datenschutzrechtlichen
                  Einwilligung
                </h5>
                <p className="part-chapter__text">
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, eine Einwilligung zur
                  Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
                  Möchte die betroffene Person ihr Recht auf Widerruf einer
                  Einwilligung geltend machen, kann sie sich hierzu jederzeit an
                  einen Mitarbeiter des für die Verarbeitung Verantwortlichen
                  wenden.
                </p>
              </div>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                10. Datenschutz bei Bewerbungen und im Bewerbungsverfahren
              </h4>
              <p className="chapter__text">
                Der für die Verarbeitung Verantwortliche erhebt und verarbeitet
                die personenbezogenen Daten von Bewerbern zum Zwecke der
                Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch
                auf elektronischem Wege erfolgen. Dies ist insbesondere dann der
                Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf
                dem elektronischen Wege, beispielsweise per E-Mail oder über ein
                auf der Internetseite befindliches Webformular, an den für die
                Verarbeitung Verantwortlichen übermittelt. Schließt der für die
                Verarbeitung Verantwortliche einen Anstellungsvertrag mit einem
                Bewerber, werden die übermittelten Daten zum Zwecke der
                Abwicklung des Beschäftigungsverhältnisses unter Beachtung der
                gesetzlichen Vorschriften gespeichert. Wird von dem für die
                Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem
                Bewerber geschlossen, so werden die Bewerbungsunterlagen zwei
                Monate nach Bekanntgabe der Absageentscheidung automatisch
                gelöscht, sofern einer Löschung keine sonstigen berechtigten
                Interessen des für die Verarbeitung Verantwortlichen
                entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne
                ist beispielsweise eine Beweispflicht in einem Verfahren nach
                dem Allgemeinen Gleichbehandlungsgesetz (AGG).
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                11. Datenschutzbestimmungen zu Einsatz und Verwendung von Google
                Analytics (mit Anonymisierungsfunktion)
              </h4>
              <p className="chapter__text">
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite die Komponente Google Analytics (mit
                Anonymisierungsfunktion) integriert. Google Analytics ist ein
                Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
                Auswertung von Daten über das Verhalten von Besuchern von
                Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem
                Daten darüber, von welcher Internetseite eine betroffene Person
                auf eine Internetseite gekommen ist (sogenannte Referrer), auf
                welche Unterseiten der Internetseite zugegriffen oder wie oft
                und für welche Verweildauer eine Unterseite betrachtet wurde.
                Eine Web-Analyse wird überwiegend zur Optimierung einer
                Internetseite und zur Kosten-Nutzen-Analyse von Internetwerbung
                eingesetzt.
                <br />
                Betreibergesellschaft der Google-Analytics-Komponente ist die
                Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
                94043-1351, USA.
                <br />
                Der für die Verarbeitung Verantwortliche verwendet für die
                Web-Analyse über Google Analytics den Zusatz
                "_gat._anonymizeIp". Mittels dieses Zusatzes wird die IP-Adresse
                des Internetanschlusses der betroffenen Person von Google
                gekürzt und anonymisiert, wenn der Zugriff auf unsere
                Internetseiten aus einem Mitgliedstaat der Europäischen Union
                oder aus einem anderen Vertragsstaat des Abkommens über den
                Europäischen Wirtschaftsraum erfolgt.
                <br />
                Der Zweck der Google-Analytics-Komponente ist die Analyse der
                Besucherströme auf unserer Internetseite. Google nutzt die
                gewonnenen Daten und Informationen unter anderem dazu, die
                Nutzung unserer Internetseite auszuwerten, um für uns
                Online-Reports, welche die Aktivitäten auf unseren
                Internetseiten aufzeigen, zusammenzustellen, und um weitere mit
                der Nutzung unserer Internetseite in Verbindung stehende
                Dienstleistungen zu erbringen.
                <br />
              </p>
              <p className="chapter__text">
                Google Analytics setzt ein Cookie auf dem
                informationstechnologischen System der betroffenen Person. Was
                Cookies sind, wurde oben bereits erläutert. Mit Setzung des
                Cookies wird Google eine Analyse der Benutzung unserer
                Internetseite ermöglicht. Durch jeden Aufruf einer der
                Einzelseiten dieser Internetseite, die durch den für die
                Verarbeitung Verantwortlichen betrieben wird und auf welcher
                eine Google-Analytics-Komponente integriert wurde, wird der
                Internetbrowser auf dem informationstechnologischen System der
                betroffenen Person automatisch durch die jeweilige
                Google-Analytics-Komponente veranlasst, Daten zum Zwecke der
                Online-Analyse an Google zu übermitteln. Im Rahmen dieses
                technischen Verfahrens erhält Google Kenntnis über
                personenbezogene Daten, wie der IP-Adresse der betroffenen
                Person, die Google unter anderem dazu dienen, die Herkunft der
                Besucher und Klicks nachzuvollziehen und in der Folge
                Provisionsabrechnungen zu ermöglichen.
              </p>
              <p className="chapter__text">
                Mittels des Cookies werden personenbezogene Informationen,
                beispielsweise die Zugriffszeit, der Ort, von welchem ein
                Zugriff ausging und die Häufigkeit der Besuche unserer
                Internetseite durch die betroffene Person, gespeichert. Bei
                jedem Besuch unserer Internetseiten werden diese
                personenbezogenen Daten, einschließlich der IP-Adresse des von
                der betroffenen Person genutzten Internetanschlusses, an Google
                in den Vereinigten Staaten von Amerika übertragen. Diese
                personenbezogenen Daten werden durch Google in den Vereinigten
                Staaten von Amerika gespeichert. Google gibt diese über das
                technische Verfahren erhobenen personenbezogenen Daten unter
                Umständen an Dritte weiter.
              </p>
              <p className="chapter__text">
                Die betroffene Person kann die Setzung von Cookies durch unsere
                Internetseite, wie oben bereits dargestellt, jederzeit mittels
                einer entsprechenden Einstellung des genutzten Internetbrowsers
                verhindern und damit der Setzung von Cookies dauerhaft
                widersprechen. Eine solche Einstellung des genutzten
                Internetbrowsers würde auch verhindern, dass Google ein Cookie
                auf dem informationstechnologischen System der betroffenen
                Person setzt. Zudem kann ein von Google Analytics bereits
                gesetzter Cookie jederzeit über den Internetbrowser oder andere
                Softwareprogramme gelöscht werden.
              </p>
              <p className="chapter__text">
                Ferner besteht für die betroffene Person die Möglichkeit, einer
                Erfassung der durch Google Analytics erzeugten, auf eine Nutzung
                dieser Internetseite bezogenen Daten sowie der Verarbeitung
                dieser Daten durch Google zu widersprechen und eine solche zu
                verhindern. Hierzu muss die betroffene Person ein Browser-Add-On
                unter dem Link
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                herunterladen und installieren. Dieses Browser-Add-On teilt
                Google Analytics über JavaScript mit, dass keine Daten und
                Informationen zu den Besuchen von Internetseiten an Google
                Analytics übermittelt werden dürfen. Die Installation des
                Browser-Add-Ons wird von Google als Widerspruch gewertet. Wird
                das informationstechnologische System der betroffenen Person zu
                einem späteren Zeitpunkt gelöscht, formatiert oder neu
                installiert, muss durch die betroffene Person eine erneute
                Installation des Browser-Add-Ons erfolgen, um Google Analytics
                zu deaktivieren. Sofern das Browser-Add-On durch die betroffene
                Person oder einer anderen Person, die ihrem Machtbereich
                zuzurechnen ist, deinstalliert oder deaktiviert wird, besteht
                die Möglichkeit der Neuinstallation oder der erneuten
                Aktivierung des Browser-Add-Ons.
                <br />
                Weitere Informationen und die geltenden Datenschutzbestimmungen
                von Google können unter
                <a href="https://www.google.de/intl/de/policies/privacy/">
                  https://www.google.de/intl/de/policies/privacy/
                </a>
                und unter
                <a href="http://www.google.com/analytics/terms/de.html">
                  http://www.google.com/analytics/terms/de.html
                </a>
                abgerufen werden. Google Analytics wird unter diesem Link
                <a href="https://www.google.com/intl/de_de/analytics/">
                  https://www.google.com/intl/de_de/analytics/
                </a>
                genauer erläutert.
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                12. Datenschutzbestimmungen zu Einsatz und Verwendung von
                Google+
              </h4>
              <p className="chapter__text">
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite als Komponente die Google+ Schaltfläche
                integriert. Google+ ist ein sogenanntes soziales Netzwerk. Ein
                soziales Netzwerk ist ein im Internet betriebener sozialer
                Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der
                Regel ermöglicht, untereinander zu kommunizieren und im
                virtuellen Raum zu interagieren. Ein soziales Netzwerk kann als
                Plattform zum Austausch von Meinungen und Erfahrungen dienen
                oder ermöglicht es der Internetgemeinschaft, persönliche oder
                unternehmensbezogene Informationen bereitzustellen. Google+
                ermöglicht den Nutzern des sozialen Netzwerkes unter anderem die
                Erstellung von privaten Profilen, den Upload von Fotos und eine
                Vernetzung über Freundschaftsanfragen.
                <br />
                Betreibergesellschaft von Google+ ist die Google Inc., 1600
                Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
                <br />
                Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
                die durch den für die Verarbeitung Verantwortlichen betrieben
                wird und auf welcher eine Google+ Schaltfläche integriert wurde,
                wird der Internetbrowser auf dem informationstechnologischen
                System der betroffenen Person automatisch durch die jeweilige
                Google+ Schaltfläche veranlasst, eine Darstellung der
                entsprechenden Google+ Schaltfläche von Google herunterzuladen.
                Im Rahmen dieses technischen Verfahrens erhält Google Kenntnis
                darüber, welche konkrete Unterseite unserer Internetseite durch
                die betroffene Person besucht wird. Genauere Informationen zu
                Google+ sind unter
                <a href="https://developers.google.com/+/">
                  https://developers.google.com/+/
                </a>
                abrufbar.
                <br />
                Sofern die betroffene Person gleichzeitig bei Google+ eingeloggt
                ist, erkennt Google mit jedem Aufruf unserer Internetseite durch
                die betroffene Person und während der gesamten Dauer des
                jeweiligen Aufenthaltes auf unserer Internetseite, welche
                konkrete Unterseite unserer Internetseite die betroffene Person
                besucht. Diese Informationen werden durch die Google+
                Schaltfläche gesammelt und durch Google dem jeweiligen
                Google+-Account der betroffenen Person zugeordnet.
                <br />
                Betätigt die betroffene Person einen der auf unserer
                Internetseite integrierten Google+-Buttons und gibt damit eine
                Google+1 Empfehlung ab, ordnet Google diese Information dem
                persönlichen Google+-Benutzerkonto der betroffenen Person zu und
                speichert diese personenbezogenen Daten. Google speichert die
                Google+1-Empfehlung der betroffenen Person und macht diese in
                Übereinstimmung mit den von der betroffenen Person diesbezüglich
                akzeptierten Bedingungen öffentlich zugänglich. Eine von der
                betroffenen Person auf dieser Internetseite abgegebene
                Google+1-Empfehlung wird in der Folge zusammen mit anderen
                personenbezogenen Daten, wie dem Namen des von der betroffenen
                Person genutzten Google+1-Accounts und dem in diesem
                hinterlegten Foto in anderen Google-Diensten, beispielsweise den
                Suchmaschinenergebnissen der Google-Suchmaschine, dem
                Google-Konto der betroffenen Person oder an sonstigen Stellen,
                beispielsweise auf Internetseiten oder im Zusammenhang mit
                Werbeanzeigen, gespeichert und verarbeitet. Ferner ist Google in
                der Lage, den Besuch auf dieser Internetseite mit anderen bei
                Google gespeicherten personenbezogenen Daten zu verknüpfen.
                Google zeichnet diese personenbezogenen Informationen ferner mit
                dem Zweck auf, die unterschiedlichen Dienste von Google zu
                verbessern oder zu optimieren.
                <br />
                Google erhält über die Google+-Schaltfläche immer dann eine
                Information darüber, dass die betroffene Person unsere
                Internetseite besucht hat, wenn die betroffene Person zum
                Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
                Google+ eingeloggt ist; dies findet unabhängig davon statt, ob
                die betroffene Person die Google+-Schaltfläche anklickt oder
                nicht.
                <br />
                Ist eine Übermittlung personenbezogener Daten an Google von der
                betroffenen Person nicht gewollt, kann diese eine solche
                Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
                unserer Internetseite aus ihrem Google+-Account ausloggt.
                <br />
                Weitere Informationen und die geltenden Datenschutzbestimmungen
                von Google können unter
                <a href="https://www.google.de/intl/de/policies/privacy/">
                  https://www.google.de/intl/de/policies/privacy/
                </a>
                abgerufen werden. Weitere Hinweise von Google zur
                Google+1-Schaltfläche können unter
                <a href="https://developers.google.com/+/web/buttons-policy">
                  https://developers.google.com/+/web/buttons-policy
                </a>
                abgerufen werden.
                <br />
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                13. Datenschutzbestimmungen zu Einsatz und Verwendung von
                LinkedIn
              </h4>
              <p className="chapter__text">
                Der für die Verarbeitung Verantwortliche hat auf dieser
                Internetseite Komponenten der LinkedIn Corporation integriert.
                LinkedIn ist ein Internetbasiertes soziales Netzwerk, das eine
                Konnektierung der Nutzer mit bestehenden Geschäftskontakten
                sowie das Knüpfen von neuen Businesskontakten ermöglicht. Über
                400 Millionen registrierte Personen nutzen LinkedIn in mehr als
                200 Ländern. Damit ist LinkedIn derzeit die größte Plattform für
                Businesskontakte und eine der meistbesuchten Internetseiten der
                Welt.
                <br />
                Betreibergesellschaft von LinkedIn ist die LinkedIn Corporation,
                2029 Stierlin Court Mountain View, CA 94043, USA. Für
                Datenschutzangelegenheiten außerhalb der USA ist LinkedIn
                Ireland, Privacy Policy Issues, Wilton Plaza, Wilton Place,
                Dublin 2, Ireland, zuständig.
                <br />
                Bei jedem einzelnen Abruf unserer Internetseite, die mit einer
                LinkedIn-Komponente (LinkedIn-Plug-In) ausgestattet ist,
                veranlasst diese Komponente, dass der von der betroffenen Person
                verwendete Browser eine entsprechende Darstellung der Komponente
                von LinkedIn herunterlädt. Weitere Informationen zu den
                LinkedIn-Plug-Ins können unter
                <a href="https://developer.linkedin.com/plugins">
                  https://developer.linkedin.com/plugins
                </a>
                abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält
                LinkedIn Kenntnis darüber, welche konkrete Unterseite unserer
                Internetseite durch die betroffene Person besucht wird.
                <br />
                Sofern die betroffene Person gleichzeitig bei LinkedIn
                eingeloggt ist, erkennt LinkedIn mit jedem Aufruf unserer
                Internetseite durch die betroffene Person und während der
                gesamten Dauer des jeweiligen Aufenthaltes auf unserer
                Internetseite, welche konkrete Unterseite unserer Internetseite
                die betroffene Person besucht. Diese Informationen werden durch
                die LinkedIn-Komponente gesammelt und durch LinkedIn dem
                jeweiligen LinkedIn-Account der betroffenen Person zugeordnet.
                Betätigt die betroffene Person einen auf unserer Internetseite
                integrierten LinkedIn-Button, ordnet LinkedIn diese Information
                dem persönlichen LinkedIn-Benutzerkonto der betroffenen Person
                zu und speichert diese personenbezogenen Daten.
                <br />
                LinkedIn erhält über die LinkedIn-Komponente immer dann eine
                Information darüber, dass die betroffene Person unsere
                Internetseite besucht hat, wenn die betroffene Person zum
                Zeitpunkt des Aufrufes unserer Internetseite gleichzeitig bei
                LinkedIn eingeloggt ist; dies findet unabhängig davon statt, ob
                die betroffene Person die LinkedIn-Komponente anklickt oder
                nicht. Ist eine derartige Übermittlung dieser Informationen an
                LinkedIn von der betroffenen Person nicht gewollt, kann diese
                die Übermittlung dadurch verhindern, dass sie sich vor einem
                Aufruf unserer Internetseite aus ihrem LinkedIn-Account
                ausloggt.
                <br />
                LinkedIn bietet unter
                <a href="https://www.linkedin.com/psettings/guest-controls">
                  https://www.linkedin.com/psettings/guest-controls
                </a>
                die Möglichkeit, E-Mail-Nachrichten, SMS-Nachrichten und
                zielgerichtete Anzeigen abzubestellen sowie
                Anzeigen-Einstellungen zu verwalten. LinkedIn nutzt ferner
                Partner wie Quantcast, Google Analytics, BlueKai, DoubleClick,
                Nielsen, Comscore, Eloqua und Lotame, die Cookies setzen können.
                Solche Cookies können unter
                <a href="https://www.linkedin.com/legal/cookie-policy">
                  https://www.linkedin.com/legal/cookie-policy
                </a>
                abgelehnt werden. Die geltenden Datenschutzbestimmungen von
                LinkedIn sind unter
                <a href="https://www.linkedin.com/legal/privacy-policy">
                  https://www.linkedin.com/legal/privacy-policy
                </a>
                abrufbar. Die Cookie-Richtlinie von LinkedIn ist unter
                <a href="https://www.linkedin.com/legal/cookie-policy">
                  https://www.linkedin.com/legal/cookie-policy
                </a>
                abrufbar.
                <br />
              </p>
            </div>
            <div className="article__chapter chapter">
              <h4 className="chapter__title">
                14. Rechtsgrundlage der Verarbeitung
              </h4>
              <p className="chapter__text">
                Art. 6 I lit. a DS-GVO dient unserem Unternehmen als
                Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
                Einwilligung für einen bestimmten Verarbeitungszweck einholen.
                Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines
                Vertrags, dessen Vertragspartei die betroffene Person ist,
                erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen
                der Fall ist, die für eine Lieferung von Waren oder die
                Erbringung einer sonstigen Leistung oder Gegenleistung notwendig
                sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO.
                Gleiches gilt für solche Verarbeitungsvorgänge die zur
                Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa
                in Fällen von Anfragen zur unseren Produkten oder Leistungen.
                Unterliegt unser Unternehmen einer rechtlichen Verpflichtung
                durch welche eine Verarbeitung von personenbezogenen Daten
                erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
                Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c
                DS-GVO. In seltenen Fällen könnte die Verarbeitung von
                personenbezogenen Daten erforderlich werden, um lebenswichtige
                Interessen der betroffenen Person oder einer anderen natürlichen
                Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein
                Besucher in unserem Betrieb verletzt werden würde und daraufhin
                sein Name, sein Alter, seine Krankenkassendaten oder sonstige
                lebenswichtige Informationen an einen Arzt, ein
              </p>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default CookiePage;
