import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

//Import Stickey Header
import StickyHeader from "react-sticky-header";
import "../../../node_modules/react-sticky-header/styles.css";
// import Logo from "../../../public/assets/images/logo.png";

class Navbar_Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Heim" },
        { id: 2, idnm: "features", navheading: "Unsere Stärken" },
        { id: 3, idnm: "services", navheading: "Finanzsoftware" },
        { id: 3, idnm: "about", navheading: "Über uns" },
        { id: 4, idnm: "pricing", navheading: "Hosting" },
        { id: 6, idnm: "contact", navheading: "Kontakt" },
      ],
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              expand="lg"
              fixed="top"
              className={
                this.props.navClass + " navbar-custom sticky sticky-dark"
              }>
              <Container>
                <NavbarBrand
                  className="logo"
                  href="/">
                  <img
                    src="assets/images/logo.png"
                    alt="..."
                  />
                </NavbarBrand>

                <NavbarToggler
                  className="p-0"
                  onClick={this.toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar>
                  <ScrollspyNav
                    scrollTargetIds={TargetId}
                    activeNavClass="active"
                    scrollDuration="800"
                    headerBackground="true">
                    <Nav
                      className="navbar-nav navbar-center"
                      id="mySidenav">
                      {this.state.navItems.map((item, key) => (
                        <NavItem
                          key={key}
                          className={
                            item.navheading === "Home" ? "active" : ""
                          }>
                          <NavLink href={"#" + item.idnm}>
                            {" "}
                            {item.navheading}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </ScrollspyNav>
                  <div className="nav-button ms-auto">
                    <Nav
                      className="navbar-right"
                      navbar>
                      <NavItem>
                        <a href="/#contact">
                          <Button
                            type="button"
                            className="btn btn-primary navbar-btn btn-rounded waves-effect waves-light">
                            Try it Free
                          </Button>
                        </a>
                      </NavItem>
                    </Nav>
                  </div>
                </Collapse>
              </Container>
            </Navbar>
          }
          stickyOffset={-100}></StickyHeader>
      </React.Fragment>
    );
  }
}

export default Navbar_Page;
