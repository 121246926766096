import { useEffect, useState } from "react";
import "./CookieModal.css";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const CookieModal = () => {
  const [hide, setHide] = useState(true);

  const handleClick = () => {
    setHide(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setHide(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`cookie__popup ${hide && "hide"}`}>
      <div className="cookie__container">
        <p className="cookie__text">
          <span>Bitte lesen und bestätigen Sie unsere </span>
          <NavLink to="/data-protection">Datenschutzerklärung</NavLink>
        </p>
        <button
          onClick={handleClick}
          className="btn btn-primary waves-effect waves-light cookie__button">
          Bestätigen
        </button>
      </div>
    </div>
  );
};

export default CookieModal;
