import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class FooterLinks extends Component {
  state = {
    socials: [
      {
        icon: "mdi mdi-linkedin",
        link: "https://www.linkedin.com/in/dima-belykh-a48312135?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      },
      {
        icon: "mdi mdi-linkedin",
        link: "https://www.linkedin.com/in/holger-praetorius-bb3274100?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <div className="footer-alt">
          <Container>
            <Row>
              <Col lg="12">
                <div className="float-start pull-none">
                  <p className="copy-rights text-muted mb-3 mb-sm-0">
                    2023 - 2024 © FOUNTAINHEAD IT
                  </p>
                </div>
                <div className="float-end pull-none ">
                  <ul className="list-inline social m-0">
                    {this.state.socials.map((social, key) => (
                      <li
                        className="list-inline-item"
                        key={key}>
                        <a
                          href={social.link}
                          className="social-icon">
                          <i className={social.icon}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="clearfix"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
