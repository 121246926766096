import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col, Form, Input } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerItems: [
        {
          logo: "assets/images/logo.png",
          links: [
            { linkTitle: "Heim", link: "/#home" },
            { linkTitle: "Über uns", link: "/#about" },
            { linkTitle: "Kontakt", link: "/#contact" },
          ],
        },
        {
          title: "Information",
          links: [
            {
              linkTitle: "Geschäftsbedingungen",
              link: "/data-protection",
              isNav: true,
            },
            { linkTitle: "Impressum", link: "/impressum", isNav: true },
          ],
        },
      ],
      isSwitchToggle: false,
    };
    this.toggleSwitcher = this.toggleSwitcher.bind(this);
    this.setTheme = this.setTheme.bind(this);
  }

  setTheme = (color) => {
    document.getElementById("colorTheme").href =
      "assets/colors/" + color + ".css";
  };

  toggleSwitcher = () => {
    this.setState({ isSwitchToggle: !this.state.isSwitchToggle });
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.footerItems.map((item, key) => (
                <Col
                  lg="3"
                  className="mt-4"
                  key={key}>
                  <h4>{item.title}</h4>
                  {item.logo && (
                    <div className="logo">
                      <img
                        src={item.logo}
                        alt="..."
                      />
                    </div>
                  )}
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {item.links.map((link, key) =>
                        link.isNav ? (
                          <li key={key}>
                            <NavLink to={link.link}>{link.linkTitle}</NavLink>
                          </li>
                        ) : (
                          <li key={key}>
                            <a href={link.link}>{link.linkTitle}</a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </footer>

        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
