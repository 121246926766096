const PricingListItem = ({ title, description }) => {
  return !!description ? (
    <li>
      <b className="text-primary">{title}: </b>
      <span>{description}</span>
    </li>
  ) : null;
};

export default PricingListItem;
