import { Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import "./ContactForm.css"

const ContactForm = () => {
  return (
    <AvForm className="contact-form__container">
      <div className="contact-form__holder">
        <AvField
          type="email"
          className="form-group contact-form__input"
          name="email"
          id="email"
          placeholder="Your email*"
          required
          errorMessage=""
          validate={{
            required: {
              value: true,
              errorMessage: "Please enter your email",
            },
          }}
        />
        <Button className="submitBnt btn btn-primary contact-form__button">Request a demo</Button>
      </div>
    </AvForm>
  );
};

export default ContactForm;

{
  /* <AvForm>
<Row
  className="mt-3"
  style={{ display: "flex", justifyContent: "center" }}>
  <Col
    xs="12"
    lg="6">
    
  </Col>
  <Col className="text-start">
    
    <div id="simple-msg"></div>
  </Col>
</Row>
</AvForm> */
}
