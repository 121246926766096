import Footer from "../../components/Footer/footer";
import { Navbar, NavbarBrand, Container } from "reactstrap";
import { useEffect } from "react";

//Import Stickey Header
import StickyHeader from "react-sticky-header";

const ImpressumPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StickyHeader
        stickyOffset={-0}
        header={
          <Navbar
            expand="lg"
            fixed="top"
            className="navbar-custom sticky sticky-dark">
            <Container>
              <NavbarBrand
                className="logo"
                href="/">
                <img
                  src="assets/images/logo.png"
                  alt="..."
                />
              </NavbarBrand>
            </Container>
          </Navbar>
        }></StickyHeader>
      <section className="home-padding-t-150 section">
        <Container>
          <div className="pb-10">
            <h2 className="tp-hero-title">Impressum</h2>
            <p>
              Fountainhead IT Praetorius & Belykh GmbH <br />
              Softwareentwicklung und Beratung
            </p>
            <div>
              <b>
                <a href="https://fountainhead-it.com/">
                  https://fountainhead-it.com/
                </a>
              </b>
            </div>
            <p>
              Römerstraße 245 <br /> 69126 Heidelberg
            </p>
            <p>Tel: +49 (0) 6221 / 3521-808</p>
            <div>
              <b>
                <a href="mailto:contact@fountainhead-it.com">
                  contact@fountainhead-it.com
                </a>
              </b>
            </div>
            <p>
              Amtsgericht <br />
              Mannheim HRB 723663
            </p>
            <p>
              USt-IdNr
              <br /> DE304327731
            </p>
            <p>
              Geschäftsführer
              <br /> Holger Praetorius | Dmytro Belykh
            </p>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ImpressumPage;
