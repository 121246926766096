import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";

class AboutUs extends Component {
  state = {
    members: [
      {
        id: 1,
        name: "Frank Johnson",
        image: "assets/images/team/img-1.jpg",
        post: "CEO",
      },
      {
        id: 2,
        name: "Elaine Stclair",
        image: "assets/images/team/img-2.jpg",
        post: "DESIGNER",
      },
      {
        id: 3,
        name: "Wanda Arthur",
        image: "assets/images/team/img-3.jpg",
        post: "DEVELOPER",
      },
      {
        id: 4,
        name: "Joshua Stemple",
        image: "assets/images/team/img-4.jpg",
        post: "MANAGER",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section
          className="section about-section"
          id="about">
          <Container>
            <Row>
              <Col lg={{ size: 8 }}>
                <div className="about-title mx-auto features-box">
                  <h2 className="font-weight-light">Über unser Unternehmen</h2>
                  <p className="section-subtitle text-muted font-secondary">
                    <strong>
                      Erfahrung trifft auf Innovation: Softwareentwicklung aus
                      Heidelberg seit 2000.
                    </strong>
                  </p>
                  {/* <p className="section-subtitle text-muted font-secondary">
                    Schluss mit der Excel-Buchhaltung: Die webbasierte Lösung
                    zur Erfassung und Bewertung von Leasingnehmer- und
                    Leasinggebersachverhalten nach IFRS 16
                  </p> */}
                  <ul className="text-muted list-unstyled mt-4 features-item-list about-list">
                    <li className="">
                      <strong>Immer für Sie da:</strong> Unsere Expertenteams
                      sind an 365 Tagen im Jahr für Sie im Einsatz.
                    </li>
                    <li className="">
                      <strong>Erfahrung trifft auf Dynamik:</strong> Mit
                      jahrzehntelanger Erfahrung und einem Netzwerk von über 400
                      Softwareentwicklern, Architekten und QA-Experten sind wir
                      optimal aufgestellt, um den sich ständig ändernden
                      Anforderungen des Marktes gerecht zu werden.
                    </li>
                    <li className="">
                      <strong>Maßgeschneiderte Teams:</strong> Für jedes Projekt
                      und Produkt wählen wir aus unserem Pool an Talenten das
                      perfekte Team aus, um Ihren individuellen Anforderungen
                      gerecht zu werden.
                    </li>
                    <li className="">
                      <strong>Effiziente Kommunikation:</strong> Bei uns gibt es
                      keine langen Umwege. Unsere Kommunikationswege sind
                      direkt, kurz und effizient.
                    </li>
                    <li className="">
                      <strong>Rundum-Service:</strong> Vom Entwurf bis zur
                      Ausführung - wir übernehmen den ganzen Software
                      Entwicklung Zyklus: Beratung, Konzeption, Entwicklung,
                      Quality Assurance, Hosting und Support für unsere Kunden.
                    </li>
                    <li className="">
                      <strong>Vielseitigkeit in unserer Expertise:</strong> Egal
                      ob einfache Anwendungen oder komplexe Projekte im Bereich
                      AI – wir bieten eine Bandbreite an Leistungen und
                      Ressourcen, um Sie bestmöglich zu unterstützen.
                    </li>
                  </ul>
                  <p className="text-muted web-desc">
                    Vertrauen Sie auf einen Partner, der Tradition mit der
                    Zukunft verbindet:
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <img className="about-image" src="assets/images/heidelberg.jpg" />
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;

// {/* <Row className="mt-5">
// {/* Render Team members */}
// {this.state.members.map((member, key) => (
//   <TeamBox
//     key={key}
//     name={member.name}
//     image={member.image}
//     post={member.post}
//   />
// ))}
// </Row> */}
