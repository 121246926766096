import React, { Component } from "react";
import NavbarPage from "../../components/Navbar/NavbarPage";
import Section from "./section";
import Clients from "../../components/Clients/clients";
import Features from "../../components/Features/features";
import Services from "../../components/Services/services";
import AboutUs from "../../components/About Us/about-us";
import Pricing from "../../components/Pricing/pricing";
import Testimonials from "../../components/Testimonials/testimonials";
import ContactUs from "../../components/Contact Us/contact-us";
import Footer from "../../components/Footer/footer";
import CookieModal from "../../components/CookieModal/CookieModal";

class Index5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "",
    };
  }
  componentDidMount() {
    document.getElementById("colorTheme").href = "assets/colors/blue.css";
  }

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage navClass={this.state.navClass} />

        {/* Importing Section */}
        <Section />

        {/* Importing Client */}
        <Clients />

        {/* Importing Services */}
        <Services />

        {/* Importing Features */}
        <Features />

        {/* Importing Website Description */}
        {/* <WebsiteDescription /> */}

        {/* Importing About Us */}
        <AboutUs />

        {/* Importing Pricing */}
        <Pricing />

        {/* Importing Testimonials */}
        <Testimonials />

        {/* Importing Get Started */}
        {/* <GetStarted /> */}

        {/* Importing Contact Us */}
        <ContactUs />

        <CookieModal />

        {/* Importing Get Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index5;
