import Index5 from "./pages/Index5/Index5";
import CookiePage from "./pages/cookie/cookie";
import ImpressumPage from "./pages/impressum/impressum";

const routes = [
  //Auth
  { path: "/data-protection", component: CookiePage },
  { path: "/impressum", component: ImpressumPage },
  { path: "/", component: Index5 },
];

export default routes;
